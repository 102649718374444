import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import PhoneInput from "react-phone-input-2";
import { useMutation, useQuery } from "react-query";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import AmountInput from "../../_metronic/layout/components/common/AmountInput";
import {
  addGolfClub,
  getCategories,
  getCities,
  getCountries,
  getPaymentProvider,
  getStates,
  uploadPicture,
} from "../../api";
import { IEndTime, IGolf, IStartTime } from "../../constants/types";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { charValidate } from "../../components/utility";
type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: IGolf;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditGolf = ({ show, handleClose, editData }: Props) => {
  let imgUrl: string = editData?.picture
    ? editData?.picture[0]
      ? editData?.picture[0]
      : ""
    : "";
  const [golfImg, setGolfImg] = useState<string>("");
  const [imgFileName, setImgFileName] = useState<string>(
    imgUrl ? imgUrl?.split("/img/")[1] : ""
  );
  const [startTimeFeilds, setStartTimeFeilds] = useState<IStartTime>({
    startTimeHour: "",
    startTimeMinute: "",
    startTimeFormat: "",
    isShowRequiredError: false,
  });
  const [endTimeFeilds, setEndTimeFeilds] = useState<IEndTime>({
    endTimeHour: "",
    endTimeMinute: "",
    endTimeFormat: "",
    isShowRequiredError: false,
  });
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState<string>("");
  const [selectedContractorID, setSelectedContractorID] = useState<string>("");
  const [selectedBookingProvider, setSelectedBookingProvider] = useState<string>("");
  // const [isEndTimeBefore, setEndTimeBefore] = useState(false);

  const profileDetailsSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    type: Yup.string().required("Hole type selection is required"),
    description: Yup.string().required("Description is required"),
    privacyDescription: Yup.string().required(
      "Privacy Description is required"
    ),
    minThreshold: Yup.string().required("ThreshHold amount is required"),
    rulesDescription: Yup.string().required("Rules Description is required"),
    latitude: Yup.number().required("Latitude is required"),
    longitude: Yup.number().required("Longitude is required"),
    addressName: Yup.string().trim(),
    street: Yup.string().trim(),
    unit: Yup.string().trim(),
    golfType: Yup.string().required("Golf type selection is required"),
    rentalGolfPrice: Yup.number().required("Rental golf price is required"),
    websiteURL: Yup.string().trim().required("Website URL is required"),
    contactNumber: Yup.string()
      .required("Mobile number is required")
      .min(10, "Mobile number format is incorrect"),
    teeTimeDescription: Yup.string().required(
      "Tee Time Description is required"
    ),
    teeTimePolicy: Yup.string().required("Tee Time Policy is required"),
    minSqueezAmount: Yup.string(),
    // golfImage: Yup.mixed().required("Golf Image is required"),
  });
  // const hours = [...Array(12)].map((_, index) =>
  //   index < 9 ? 0 + `${index + 1}` : `${index + 1}`
  // );
  // const minutes = [...Array(60)].map((_, index) =>
  //   index <= 9 ? 0 + `${index}` : `${index}`
  // );

  const {
    data: countryData,
    isLoading: isLoadingCountry,
    error: countryAPIError,
  } = useQuery("countries", getCountries);
  const {
    data: stateData,
    mutateAsync: getStateData,
    isLoading: isLoadingState,
    error: stateAPIError,
  } = useMutation("states", getStates);
  const {
    data: cityData,
    mutateAsync: getCityData,
    isLoading: isLoadingCity,
    error: cityAPIError,
  } = useMutation("cities", getCities);
  const {
    mutateAsync: addGolf,
    isLoading: isAddingGolf,
    error: addGoldAPIError,
  } = useMutation("add-golf", addGolfClub);
  const {
    data: categoryData,
    isLoading: isLoadingCategory,
    error: categoryAPIError,
    refetch: refetchCategories,
  } = useQuery("category", getCategories);
  const { mutateAsync: pictureUpload } = useMutation(
    "upload-image",
    uploadPicture
  );

  const { data, isLoading, error ,  mutateAsync: getproviderData} = useMutation("paymentProvider", getPaymentProvider);

  const restaurant = categoryData?.data?.docs?.find(
    (s: any) => s?._id == "65c61866ea562b9cfd579468"
  );

  const formik: any = useFormik({
    initialValues: {
      name: editData?.name ? editData?.name?.replace(/^\s+|\s+$/g, "") : "",
      type: editData?.type ?? "",
      description: editData?.description ?? "",
      latitude: editData?.Latitude ?? "",
      longitude: editData?.Longitude ?? "",
      addressName: editData?.address?.name
        ? editData?.address?.name?.replace(/^\s+|\s+$/g, "")
        : "",
      street: editData?.address?.street
        ? editData?.address?.street?.replace(/^\s+|\s+$/g, "")
        : "",
      unit: editData?.address?.unit ?? "",
      minThreshold: editData?.minThreshold ?? 0,
      city: editData?.address?.cityId ?? "",
      zip: editData?.address?.zip ?? "",
      state: editData?.address?.stateId ?? "",
      country: editData?.address?.countryId ?? "",
      golfType: editData?.golfType ?? "",
      privacyDescription: editData?.privacyDescription ?? "",
      rulesDescription: editData?.rulesDescription ?? "",
      minAmount: editData?.min ?? "",
      maxAmount: editData?.max ?? "",
      rentalGolfPrice: editData?.rentalGolfPrice ?? 0,
      tax: editData?.tax ?? 0,
      convenienceFee: editData?.convenienceFee ?? 0,
      websiteURL: editData?.websiteURL
        ? editData?.websiteURL?.replace(/^\s+|\s+$/g, "")
        : "",
      contactNumber: editData?.contactNumber ?? "",
      countryCode: editData?.countryCode ?? "",
      teeTimeDescription: editData?.teeTimeDescription ?? "",
      teeTimePolicy: editData?.teeTimePolicy ?? "",
      minSqueezAmount: editData?.minSqueezAmount ?? 0,
      IsAutoApprove: editData?.IsAutoApprove ?? true,
      merchantID: editData?.merchantID,
      payment_provider: Object.keys(editData?.paymentInfo || {})[0] || "",
      public_private_key: editData?.paymentInfo?.[Object.keys(editData?.paymentInfo || {})[0]]?.fields?.public_private_key || "",
      contractorID: editData?.paymentInfo?.[Object.keys(editData?.paymentInfo || {})[0]]?.fields?.contractorFields?.[0]?.contractorFieldId || "",
      split_contractor_provider: editData?.paymentInfo?.[Object.keys(editData?.paymentInfo || {})[0]]?.fields?.contractorFields?.[0]?.contractorName || "",
      booking_provider: Object.keys(editData?.bookingInfo || {})[0] || "",
      venueID: editData?.bookingInfo?.[Object.keys(editData?.bookingInfo || {})[0]]?.fields?.venueId || "",
      secret_key: editData?.paymentInfo?.[Object.keys(editData?.paymentInfo || {})[0]]?.fields?.secret_key || "",

    },
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      const paymentProviderData = {
        [values.payment_provider]: {
          fields: {
            public_private_key: values.payment_provider == "fiserv" ? values.public_private_key : "",
            secret_key:  values.payment_provider == "shift4" ? values.secret_key : "",

            accountId: values.merchantID,
            contractorFields: [
              {
                contractorFieldId: values.contractorID,
                contractorName: values.split_contractor_provider,
              },
            ],
          },
        },
      };

      const bookingProviderData = {
        [values.booking_provider]: {
          fields: {
            venueId:values.venueID,
            accountId: values.booking_provider,
          },
        },
      };

      const country = countryData?.data?.find(
        (c: any) => c?.countryId == values?.country
      );
      const state = stateData?.data?.find(
        (s: any) => s?.stateId == values?.state
      );
      const city = cityData?.data?.find((c: any) => c?.cityId == values?.city);
      let bodyParams = {
        _id: editData ? editData?._id : undefined,
        name: values?.name ? values?.name?.replace(/^\s+|\s+$/g, "") : "",
        type: values?.type ?? "",
        description: values?.description ?? "",
        address: {
          name: values?.addressName
            ? values?.addressName?.replace(/^\s+|\s+$/g, "")
            : "",
          street: values?.street
            ? values?.street?.replace(/^\s+|\s+$/g, "")
            : "",
          unit: values?.unit ? values?.unit?.replace(/^\s+|\s+$/g, "") : "",
          cityId: values?.city ?? "",
          zip: values?.zip ?? "",
          stateId: values?.state ?? "",
          countryId: values?.country ?? "",
          country: country?.name ?? "",
          state: state?.name ?? "",
          city: city?.name ?? "",
        },
        Latitude: values?.latitude ?? "",
        Longitude: values?.longitude ?? "",
        golfType: values?.golfType ?? "",
        privacyDescription: values?.privacyDescription ?? "",
        rulesDescription: values?.rulesDescription ?? "",
        min: values?.minAmount ?? "",
        max: values?.maxAmount ?? "",
        rentalGolfPrice: values?.rentalGolfPrice ?? 0,
        tax: values?.tax ?? 0,
        minThreshold:values?.minThreshold ?? 0,
        convenienceFee: values?.convenienceFee ?? 0,
        websiteURL: values?.websiteURL
          ? values?.websiteURL?.replace(/^\s+|\s+$/g, "")
          : "",
        contactNumber: values?.contactNumber
          ? values?.contactNumber?.slice(`${values?.countryCode?.length}`)
          : "",
        countryCode: values?.countryCode ?? "1",
        picture: golfImg ? [golfImg] : "",
        teeTimeDescription: values?.teeTimeDescription ?? "",
        teeTimePolicy: values?.teeTimePolicy ?? "",
        minSqueezAmount: values?.minSqueezAmount ?? 0,
        IsAutoApprove: values?.IsAutoApprove ?? true,
        merchantID: values?.merchantID,
        paymentInfo: paymentProviderData,
        bookingInfo: bookingProviderData,
        booking_provider: values.booking_provider,
        split_contractor_provider: values.split_contractor_provider,
      };  
      let response = await addGolf(bodyParams);
      if (response?.status) {
        snackActions.success(response?.message ?? "Golf Added Successfully!");
        formik.resetForm();
        setImgFileName("");
        setGolfImg("");
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });
  const countryValue = formik.values.country;
  const stateValue = formik.values.state;

  useEffect(() => {
    if (editData) {
      formik.setFieldValue(
        "contactNumber",
        editData?.countryCode + editData?.contactNumber
      );
      if (imgUrl) {
        setGolfImg(imgUrl);
      }

      const paymentInfo = editData.paymentInfo || {};
      const bookingInfo = editData.bookingInfo || {};

      const paymentProvider = Object.keys(paymentInfo)[0] || "";
      const bookingProvider = Object.keys(bookingInfo)[0] || "";
      const providerData = paymentInfo[paymentProvider] || {};
      const contractorFields = providerData.fields?.contractorFields || [];
      const venueID = bookingInfo?.[Object.keys(bookingInfo || {})[0]]?.fields?.venueId || "";

      setSelectedPaymentProvider(paymentProvider);
      setSelectedBookingProvider(bookingProvider);
      setSelectedContractorID(contractorFields[0]?.contractorName || "");

      formik.setFieldValue('payment_provider', paymentProvider);
      formik.setFieldValue('merchantID', providerData.fields?.accountId || '');
      formik.setFieldValue('public_private_key', providerData.fields?.public_private_key || '');
      formik.setFieldValue('contractorID', contractorFields[0]?.contractorFieldId || "");
      formik.setFieldValue('split_contractor_provider', contractorFields[0]?.contractorName || "");
      formik.setFieldValue('booking_provider', bookingProvider);
      formik.setFieldValue('venueID', venueID);
    }
  }, [editData]);

  useEffect(() => {
    if (countryValue) {
      getStateData({ countryId: countryValue });
    }
  }, [countryValue]);

  useEffect(() => {
    if (stateValue) {
      getCityData({ stateId: stateValue });
    }
  }, [stateValue]);

  useEffect(() => {
    if (editData || restaurant?._id) {
      getproviderData({ catId:editData ?  editData?.cateId :restaurant?._id});
    }
  }, [editData, restaurant?._id]);


  // const handleTimeSelection = (
  //   value: string | number,
  //   fieldName: string,
  //   type: string,
  // ) => {
  //   if (type === "START_TIME") {
  //     setStartTimeFeilds((prevState: IStartTime) => {
  //       return {
  //         ...prevState,
  //         [fieldName]: value,
  //         isShowRequiredError: false,
  //       };
  //     });
  //   } else {
  //     setEndTimeFeilds((prevState: IEndTime) => {
  //       return {
  //         ...prevState,
  //         [fieldName]: value,
  //         isShowRequiredError: false,
  //       };
  //     });
  //   }
  //   setEndTimeBefore(false);
  // };

  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setImgFileName(file?.name ?? "");
    formData.append("photo", file);
    if (file) {
      let response = await pictureUpload(formData);
      if (response?.status) {
        setGolfImg(response?.data?.images);
      } else {
        snackActions.error(
          response?.message ?? "Issue in image upload, try again latter!"
        );
      }
    }
  };

  const handlePaymentProviderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedName = e.target.value;
    setSelectedPaymentProvider(selectedName);
    formik.setFieldValue('payment_provider', selectedName);
  };

  const handleContractorProviderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedName = e.target.value;
    setSelectedContractorID(selectedName);
    formik.setFieldValue('split_contractor_provider', selectedName);
  };

  const handleBookingProviderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const provider = e.target.value;
    setSelectedBookingProvider(provider);
    formik.setFieldValue('booking_provider', provider);
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => {
        setImgFileName("");
        setGolfImg("");
        handleClose(false);
      }}
      backdrop="static"
    >
      <div className="modal-header">
        <h2> {editData?._id ? "Edit" : "Add"} Golf</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => {
            setImgFileName("");
            setGolfImg("");
            handleClose(false);
          }}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6" style={{ height: "80vh", overflowY: "auto" }}>
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <Container fluid>
            <Row>
              <Col xs={6}>
                <label className="form-label fw-bold">Name</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Name"
                  aria-label="Name"
                  {...formik.getFieldProps("name")}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <div>
                  <label className="form-label fw-bold">Hole Type</label>
                  <select
                    className="form-select form-select-solid form-select-lg"
                    {...formik.getFieldProps("type")}
                  >
                    <option value="">Select Type</option>
                    <option value="9Holes">9 Holes</option>
                    <option value="18Holes">18 Holes</option>
                  </select>
                  {formik.touched.type && formik.errors.type && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.type}</div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            <Row className="pt-6">
              <Col xs={6}>
                <label className="form-label fw-bold">Golf Type</label>
                <select
                  {...formik.getFieldProps("golfType")}
                  className="form-select form-select-solid form-select-lg"
                >
                  <option value="">Select All</option>
                  <option value="Public">Public</option>
                  <option value="Private">Private</option>
                  <option value="SemiPrivate">SemiPrivate</option>
                  <option value="Resort">Resort</option>
                </select>
                {formik.touched.golfType && formik.errors.golfType && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.golfType}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Image upload</label>
                <div>
                  <label
                    htmlFor="golf-img"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                  >
                    {imgFileName
                      ? charValidate(imgFileName, 25)
                      : "Choose File"}
                  </label>
                  <input
                    id="golf-img"
                    type="file"
                    accept="image/*"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Upload Image"
                    aria-label="Upload Image"
                    onChange={handleChangeImageUpload}
                    style={{
                      visibility: "hidden",
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className="pt-6">
              <Col xs={12}>
                <label className="form-label fw-bold">Description</label>
                <ReactQuill
                  theme="snow"
                  placeholder="Write something..."
                  modules={AddEditGolf.modules}
                  formats={AddEditGolf.formats}
                  value={formik.values.description}
                  onChange={(content) =>
                    formik.setFieldValue("description", content)
                  }
                  style={{
                    height: "12rem",
                  }}
                />
              </Col>
            </Row>

            {formik.touched.description && formik.errors.description && (
              <div className="fv-plugins-message-container mt-14">
                <div className="fv-help-block">{formik.errors.description}</div>
              </div>
            )}

            <Row
              className={`${
                formik.touched.description && formik.errors.description
                  ? "pt-5"
                  : "pt-17"
              }`}
            >
              <Col xs={12}>
                <label className="form-label fw-bold">
                  Golf Privacy Description
                </label>
                <ReactQuill
                  theme="snow"
                  placeholder="Write something..."
                  modules={AddEditGolf.modules}
                  formats={AddEditGolf.formats}
                  value={formik.values.privacyDescription}
                  onChange={(content) =>
                    formik.setFieldValue("privacyDescription", content)
                  }
                  style={{
                    height: "12rem",
                  }}
                />
              </Col>
            </Row>

            {formik.touched.privacyDescription &&
              formik.errors.privacyDescription && (
                <div className="fv-plugins-message-container mt-14">
                  <div className="fv-help-block">
                    {formik.errors.privacyDescription}
                  </div>
                </div>
              )}

            <Row
              className={`${
                formik.touched.privacyDescription &&
                formik.errors.privacyDescription
                  ? "pt-5"
                  : "pt-17"
              }`}
            >
              <Col xs={12}>
                <label className="form-label fw-bold">
                  Golf Rules Description
                </label>
                <ReactQuill
                  theme="snow"
                  placeholder="Write something..."
                  modules={AddEditGolf.modules}
                  formats={AddEditGolf.formats}
                  value={formik.values.rulesDescription}
                  onChange={(content) =>
                    formik.setFieldValue("rulesDescription", content)
                  }
                  style={{
                    height: "12rem",
                  }}
                />
              </Col>
            </Row>

            {formik.touched.rulesDescription && formik.errors.rulesDescription && (
              <div className="fv-plugins-message-container mt-14">
                <div className="fv-help-block">
                  {formik.errors.rulesDescription}
                </div>
              </div>
            )}

            <Row
              className={`${
                formik.touched.rulesDescription &&
                formik.errors.rulesDescription
                  ? "pt-5"
                  : "pt-17"
              }`}
            >
              <Col xs={12}>
                <label className="form-label fw-bold">
                  Tee Time Description
                </label>
                <ReactQuill
                  theme="snow"
                  placeholder="Write something..."
                  modules={AddEditGolf.modules}
                  formats={AddEditGolf.formats}
                  value={formik.values.teeTimeDescription}
                  onChange={(content) =>
                    formik.setFieldValue("teeTimeDescription", content)
                  }
                  style={{
                    height: "12rem",
                  }}
                />
              </Col>
            </Row>

            {formik.touched.teeTimeDescription &&
              formik.errors.teeTimeDescription && (
                <div className="fv-plugins-message-container mt-14">
                  <div className="fv-help-block">
                    {formik.errors.teeTimeDescription}
                  </div>
                </div>
              )}

            <Row
              className={`${
                formik.touched.teeTimeDescription &&
                formik.errors.teeTimeDescription
                  ? "pt-5"
                  : "pt-17"
              }`}
            >
              <Col xs={12}>
                <label className="form-label fw-bold">Tee Time Policy</label>
                <ReactQuill
                  theme="snow"
                  placeholder="Write something..."
                  modules={AddEditGolf.modules}
                  formats={AddEditGolf.formats}
                  value={formik.values.teeTimePolicy}
                  onChange={(content) =>
                    formik.setFieldValue("teeTimePolicy", content)
                  }
                  style={{
                    height: "12rem",
                  }}
                />
              </Col>
            </Row>

            {formik.touched.teeTimePolicy && formik.errors.teeTimePolicy && (
              <div className="fv-plugins-message-container mt-14">
                <div className="fv-help-block">
                  {formik.errors.teeTimePolicy}
                </div>
              </div>
            )}

            <Row
              className={`${
                formik.touched.rulesDescription &&
                formik.errors.rulesDescription
                  ? "pt-6"
                  : "pt-17"
              }`}
            >
              <Col xs={6}>
                <label className="form-label fw-bold">Latitude</label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Latitude"
                  aria-label="Latitude"
                  {...formik.getFieldProps("latitude")}
                />
                {formik.touched.latitude && formik.errors.latitude && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.latitude}
                    </div>
                  </div>
                )}
              </Col>

              <Col xs={6}>
                <label className="form-label fw-bold">Longitude</label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Longitude"
                  aria-label="Longitude"
                  {...formik.getFieldProps("longitude")}
                />
                {formik.touched.longitude && formik.errors.longitude && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.longitude}
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <label className="col-lg-4 col-form-label fw-bold fs-6 mt-3">
              <span>Address</span>
            </label>

            <Row>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Address Name</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Name"
                  aria-label="Name"
                  {...formik.getFieldProps("addressName")}
                />
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Street</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Street"
                  aria-label="Street"
                  {...formik.getFieldProps("street")}
                />
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Unit</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Unit"
                  aria-label="Unit"
                  {...formik.getFieldProps("unit")}
                />
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Country</label>
                <select
                  disabled={isLoadingCountry}
                  className="form-select form-select-solid form-select-lg"
                  {...formik.getFieldProps("country")}
                  onChange={(e) => {
                    formik.handleChange(e);
                    let selectedCountry = e.target.value;
                    if (!selectedCountry) {
                      formik.setFieldValue("state", "");
                      formik.setFieldValue("city", "");
                      stateData.data = "";
                      cityData.data = "";
                    }
                  }}
                >
                  <option value="">Select Country</option>
                  {countryData?.data
                    ? countryData?.data?.map((country: any) => (
                        <option
                          value={country?.countryId}
                          key={country?._id}
                        >{`${country?.name} (${country?.code})`}</option>
                      ))
                    : null}
                </select>
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">State</label>
                <select
                  disabled={isLoadingState}
                  className="form-select form-select-solid form-select-lg"
                  {...formik.getFieldProps("state")}
                  onChange={(e) => {
                    formik.handleChange(e);
                    const selectedState = e.target.value;
                    if (!selectedState) {
                      formik.setFieldValue("city", "");
                      cityData.data = "";
                    }
                  }}
                >
                  <option value="">Select State</option>
                  {stateData?.data
                    ? stateData?.data?.map((state: any) => (
                        <option value={state?.stateId} key={state?._id}>
                          {state?.name}
                        </option>
                      ))
                    : null}
                </select>
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">City</label>
                <select
                  disabled={isLoadingCity}
                  className="form-select form-select-solid form-select-lg"
                  {...formik.getFieldProps("city")}
                >
                  <option value="">Select City</option>
                  {cityData?.data
                    ? cityData?.data?.map((city: any) => (
                        <option value={city?.cityId} key={city?._id}>
                          {city?.name}
                        </option>
                      ))
                    : null}
                </select>
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Zip</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Zip"
                  aria-label="Zip"
                  {...formik.getFieldProps("zip")}
                />
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">
                  Rental Golf Price (Amount)
                </label>
                <AmountInput
                  placeholder="Rental Golf Price"
                  formikProps={{ ...formik.getFieldProps("rentalGolfPrice") }}
                  handleChange={(e) => formik.handleChange(e)}
                />
                {formik.touched.rentalGolfPrice &&
                  formik.errors.rentalGolfPrice && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.rentalGolfPrice}
                      </div>
                    </div>
                  )}
              </Col>
            </Row>
            <Row className="pt-6">
              <Col xs={6}>
                <label className="form-label fw-bold">Tax (%)</label>
                <AmountInput
                  placeholder="Tax"
                  formikProps={{ ...formik.getFieldProps("tax") }}
                  handleChange={(e) => formik.handleChange(e)}
                  isPercentage={true}
                />
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">
                  Convenience Fee (Amount)
                </label>
                <AmountInput
                  placeholder="Convenience Fee"
                  formikProps={{ ...formik.getFieldProps("convenienceFee") }}
                  handleChange={(e) => formik.handleChange(e)}
                />
              </Col>
            </Row>
            <Row className="pt-6">
              <Col xs={6}>
                <label className="form-label fw-bold">Website URL</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Website URL"
                  aria-label="Website URL"
                  {...formik.getFieldProps("websiteURL")}
                />
                {formik.touched.websiteURL && formik.errors.websiteURL && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.websiteURL}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Mobile Number</label>
                <PhoneInput
                  country={"us"}
                  // variant="outlined"
                  onBlur={formik.handleBlur}
                  value={formik.values.contactNumber}
                  onChange={(value: string, country: any) => {
                    formik.setFieldValue(
                      "countryCode",
                      country?.dialCode || ""
                    );
                    formik.setFieldValue("contactNumber", value || "");
                  }}
                  inputStyle={{
                    background: "#f9f9f9",
                    border: "none",
                  }}
                  inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  dropdownStyle={{
                    maxHeight: "80px",
                  }}
                  // disableDropdown={true}
                  // disableCountryCode={true}
                  // onlyCountries={["us"]}
                />
                {formik.touched.contactNumber && formik.errors.contactNumber && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.contactNumber}
                    </div>
                  </div>
                )}
              </Col>
           
              <Row className="pt-6">
                <Col xs={6}>
                  <label className="form-label fw-bold">
                  Minimum Threshold Amount
                  </label>
                  <AmountInput
                    placeholder="Minimum Threshold Amount"
                    formikProps={{ ...formik.getFieldProps("minThreshold") }}
                    handleChange={(e) => formik.handleChange(e)}
                  />
                  {formik.touched.minThreshold && formik.errors.minThreshold && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.minThreshold}
                      </div>
                    </div>
                  )}
                </Col>
                <Col xs={6}>
                  <label className="form-label fw-bold">
                  Maximum Threshold Amount
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Maximum Threshold Amount"
                    {...formik.getFieldProps("minSqueezAmount")}
                  />
                  {formik.touched.minSqueezAmount &&
                    formik.errors.minSqueezAmount && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.minSqueezAmount}
                        </div>
                      </div>
                    )}
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">
                    Merchant ID
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Merchant ID"
                    {...formik.getFieldProps("merchantID")}
                  />
                  {formik.touched.merchantID &&
                    formik.errors.merchantID && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.merchantID}
                        </div>
                      </div>
                    )}
                </Col>

              </Row>
              <Row className="pt-6">

              <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">Auto Approve</label>
                  
                  <Form.Check
                    type="switch"
                    checked={formik.values.IsAutoApprove}
                    id="custom-switch"
                    {...formik.getFieldProps("IsAutoApprove")}
                    className="cursor-pointer"
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>
            </Row>

              {/* Payment Provider */}
              <Row>
                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">
                    Payment Provider
                  </label>
                  <select
                    className="form-select form-select-solid form-select-lg"
                    {...formik.getFieldProps("payment_provider")}
                    onChange={handlePaymentProviderChange}
                  >
                    <option value="">Select Payment Provider</option>
                    {data?.paymentProviders?.map((provider: any) => (
                      <option value={provider.name} key={provider.name}>
                        {provider.name}
                      </option>
                    ))}
                  </select>
                </Col>

                {selectedPaymentProvider === "fiserv" && (
                  <>
                    <Col xs={6} className="pt-6">
                      <label className="form-label fw-bold">
                        Merchant ID
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Merchant ID"
                        {...formik.getFieldProps("merchantID")}
                      />
                    </Col>
                    <Col xs={6} className="pt-6">
                      <label className="form-label fw-bold">
                        Private Key
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Private Key"
                        {...formik.getFieldProps("public_private_key")}
                      />
                    </Col>

                  </>
                )}

                {selectedPaymentProvider === "shift4" && (
                  <>
                    <Col xs={6} className="pt-6">
                      <label className="form-label fw-bold">
                        Secret Key
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Secret Key"
                        {...formik.getFieldProps("secret_key")}
                      />
                    </Col>
                  </>
                )}


                {selectedPaymentProvider.toLocaleLowerCase() === "Stripe".toLocaleLowerCase() && (
                  <>
                    <Col xs={6} className="pt-6">
                      <label className="form-label fw-bold">
                        Merchant ID
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Merchant ID"
                        {...formik.getFieldProps("merchantID")}
                      />
                    </Col>

                    <Col xs={6} className="pt-6">
                      <label className="form-label fw-bold">
                        Partner ID
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder=" Partner ID"
                        {...formik.getFieldProps("partnerID")}
                      />
                    </Col>
                  </>

                )}

                {/* {selectedPaymentProvider === "" && (
                  <Col xs={12} className="pt-1">
                    <p style={{ color: 'red' }}>Please select a payment provider to see the related fields.</p>
                  </Col>
                )} */}

              </Row>
              {/* contractorID */}
              <Row>
                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">
                    Split percentage to selected contractor
                  </label>
                  <select
                    className="form-select form-select-solid form-select-lg"
                    {...formik.getFieldProps("split_contractor_provider")}
                    onChange={handleContractorProviderChange}
                  >
                    <option value="">Select Contractor Provider</option>
                    {data?.contractorProviders?.map((provider: any) => (
                      <option value={provider.name} key={provider.name}>
                        {provider.name}
                      </option>
                    ))}
                  </select>
                </Col>

                {selectedContractorID !== '' && (
                  <>
                    <Col xs={6} className="pt-6">
                      <label className="form-label fw-bold">
                        Contractor ID (Split 5%)
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Contractor ID"
                        {...formik.getFieldProps("contractorID")}
                      />
                    </Col>

                  </>
                )}

              </Row>
              {/* Booking Provider */}
              <Row>
                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">
                    Booking Provider
                  </label>
                  <select
                    className="form-select form-select-solid form-select-lg"
                    {...formik.getFieldProps("booking_provider")}
                    onChange={handleBookingProviderChange}
                  >
                    <option value="">Select Booking Provider</option>
                    {data?.bookingProviders?.map((provider: any) => (
                      <option value={provider.name} key={provider.name}>
                        {provider.name}
                      </option>
                    ))}
                  </select>
                </Col>

                {selectedBookingProvider.toLocaleLowerCase() === "mozrest".toLocaleLowerCase() && (
                  <>
                    <Col xs={6} className="pt-6">
                      <label className="form-label fw-bold">
                        Venue ID
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Venue ID"
                        {...formik.getFieldProps("venueID")}
                      />
                    </Col>
                  </>

                )}

              </Row>
          </Container>
          <div className="w-100 d-flex align-items-center justify-content-end mt-5">
            <Button
              onClick={() => {
                formik.resetForm();
                setImgFileName("");
                setGolfImg("");
                handleClose(false);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
              {isAddingGolf ? "Loading..." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  );
};

AddEditGolf.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};
AddEditGolf.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];

export default AddEditGolf;
