import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Grid, TextField, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { isAfter, isToday, parse } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { KTCard } from "../../_metronic/helpers";
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";
import { PageTitle } from "../../_metronic/layout/core";
import {
  getAllGolfClub,
  getAllHealthCareList,
  getAllHotelList,
  getAllWellnessList,
  getCategories,
  getCategoryRestaurant,
  getSquezzMeList,
} from "../../api";
import ApproveSquezzMe from "../../components/squezz-me-info/ApproveSquezzMe";
import RejectSquezzMe from "../../components/squezz-me-info/RejectSqueezMe";
import SquezzMeInfoDetails from "../../components/squezz-me-info/SquezzMeInfoDetails";
import MuiTable from "../../components/table/MuiTable";
import { charValidate, convertTo24HourFormat } from "../../components/utility";
import { formatDate } from "../../helpers/commonFunctions";
import useDebounce from "../../hooks/useDebounce";

const SquezzMe: React.FC = () => {
  const [showRejectRequestConfirmModal, setshowRejectRequestConfirmModal] =
    useState(false);
  const [showAcceptRequestConfirmModal, setshowAcceptRequestConfirmModal] =
    useState(false);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [categoryIdOnChange, setCategoryIdOnChange] = useState(
    "65c608806782899b0698f069"
  );
  const [itemsData, setItemsData] = useState([]);
  const [selectedItemsValue, setSelectedItemsValue] = useState("");
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
    categoryId: categoryIdOnChange || "65c608806782899b0698f069",
    itemId: "",
  });
  const tableRef = useRef();
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

  const {
    mutateAsync: getSquezzMedata,
    data: restaurantData,
    isLoading: isGettingrestaurantData,
  } = useMutation("squezz-me-list", getSquezzMeList);

  const { data: categoryData } = useQuery("category", getCategories);

  const defaultQuery = {
    offset: 0,
    limit: 1000,
    search: "",
    categoryId: "",
    itemId: "",
  };

  const { mutateAsync: getGolfData, data: golfData } = useMutation(
    "golf-list",
    getAllGolfClub
  );

  const { mutateAsync: getRestaurantAPI } = useMutation(
    "restaurant-list",
    getCategoryRestaurant
  );

  const { mutateAsync: getWellNessAPI } = useMutation(
    "wellness-list",
    getAllWellnessList
  );

  const { mutateAsync: getHealthCareAPI } = useMutation(
    "healthCare-list",
    getAllHealthCareList
  );

  const { mutateAsync: getHotelCareAPI } = useMutation(
    "hotel-list",
    getAllHotelList
  );

  useEffect(() => {
    (async () => {
      const res =
        categoryIdOnChange === "65c608806782899b0698f069"
          ? await getRestaurantAPI(defaultQuery)
          : categoryIdOnChange === "65c617ba6782899b069910fd"
          ? await getHotelCareAPI(defaultQuery)
          : categoryIdOnChange === "65c61866ea562b9cfd579468"
          ? await getGolfData(defaultQuery)
          : categoryIdOnChange === "65c9c6a05d13bb9dd9b3bfeb"
          ? await getHealthCareAPI(defaultQuery)
          : categoryIdOnChange === "6633225437f7eb145bc1e92f"
          ? await getWellNessAPI(defaultQuery)
          : null;
      setItemsData(res?.data?.docs);
    })();
  }, [categoryIdOnChange]);

  const handleCategoryChange = (e: any) => {
    setCategoryIdOnChange(e);
  };

  useEffect(() => {
    getSquezzMe(tableOptions);
  }, []);

  useEffect(() => {
    getSquezzMe({
      ...tableOptions,
      search: debounceSearchValue ? debounceSearchValue : "",
    });
  }, [debounceSearchValue, categoryIdOnChange, selectedItemsValue]);

  const getSquezzMe = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined
  ) => {
    let response = await getSquezzMedata(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "User Name",
      flex: 1.2,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row?.userId ? (
              <h6 style={{ color: "white" }}>{data?.row?.userId?.firstName}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.userId
              ? charValidate(
                  data?.row?.userId?.firstName +
                    " " +
                    data?.row?.userId?.lastName,
                  30
                )
              : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1.1,
      sortable: false,
      renderCell: (data: any) =>
        categoryData?.data?.docs?.find((s: any) => s?._id == data?.row?.catId)
          ?.name,
    },
    {
      field: "item",
      headerName: "Item Name",
      flex: 1.2,
      sortable: false,
      renderCell: (data) => (
        <CustomTooltip
          title={
            data?.row?.itemId ? (
              <h6 style={{ color: "white" }}>{data?.row?.itemId?.name}</h6>
            ) : (
              "N/A"
            )
          }
        >
          <span>
            {data?.row?.itemId
              ? charValidate(data?.row?.itemId?.name, 30)
              : "N/A"}
          </span>
        </CustomTooltip>
      ),
    },
    {
      field: "date",
      headerName: "Date (MM/DD/YY)",
      flex: 1,
      sortable: false,
      align: "center",
      renderCell: (data: any) =>
        data?.row?.slotDate
          ? formatDate(data?.row?.slotDate, "MM/dd/yy")
          : "N/A",
    },
    {
      field: "earliestTime",
      headerName: "Earliest Time",
      flex: 0.8,
      sortable: false,
      align: "center",
      renderCell: (data) => convertTo24HourFormat(data?.row?.startTime),
    },
    {
      field: "latestTime",
      headerName: "Latest Time",
      flex: 0.8,
      sortable: false,
      align: "center",
      renderCell: (data) => convertTo24HourFormat(data?.row?.endTime),
    },
    {
      field: "price",
      headerName: "Price ($)",
      minWidth: 95,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => data?.row?.requestPrice ?? "N/A",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1.2,
      sortable: false,
      renderCell: (data: any) => data?.row?.statustext ?? "N/A",
    },
    // {
    //   field: "isActive",
    //   headerName: "Status",
    //   flex: 0.7,
    //   sortable: false,
    //   renderCell: (data: any) =>
    //     typeof data?.row?.status !== "undefined" ? (
    //       data?.row?.status ? (
    //         <div className="d-flex align-items-center">
    //           <CheckCircleIcon sx={{ fontSize: 15, mr: 1, color: "green" }} />
    //           <span>Active</span>
    //         </div>
    //       ) : (
    //         <div className="d-flex align-items-center">
    //           <CheckCircleIcon sx={{ fontSize: 15, mr: 1 }} color="disabled" />
    //           <span>Inactive</span>
    //         </div>
    //       )
    //     ) : (
    //       "N/A"
    //     ),
    // },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "right",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Info">
            <InfoIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowDetailsPopup(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer", marginRight: 1.5 }}
              color="primary"
            />
          </Tooltip>
          <Tooltip title="Accept">
            <CheckCircleIcon
              onClick={() => {
                if (
                  data?.row?.status == 0 &&
                  dateTimeValidate(formatDate(data?.row?.slotDate, "MM/dd/yy"))
                ) {
                  setSelectedRow(data.row);
                  setshowAcceptRequestConfirmModal(true);
                }
              }}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color={
                data?.row?.status == 0 &&
                dateTimeValidate(formatDate(data?.row?.slotDate, "MM/dd/yy"))
                  ? "success"
                  : "disabled"
              }
            />
          </Tooltip>
          <Tooltip title="Reject">
            <DoDisturbOnIcon
              onClick={() => {
                if (
                  data?.row?.status == 0 &&
                  dateTimeValidate(formatDate(data?.row?.slotDate, "MM/dd/yy"))
                ) {
                  setSelectedRow(data.row);
                  setshowRejectRequestConfirmModal(true);
                }
              }}
              sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
              color={
                data?.row?.status == 0 &&
                dateTimeValidate(formatDate(data?.row?.slotDate, "MM/dd/yy"))
                  ? "error"
                  : "disabled"
              }
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const dateTimeValidate = (dateString: string) => {
    if (dateString && typeof dateString !== "undefined") {
      const inputDateTime = parse(dateString, "MM/dd/yy", new Date());
      const todayDate = new Date();
      return isToday(inputDateTime) || isAfter(inputDateTime, todayDate);
    } else {
      return false;
    }
  };

  const handleAcceptClose = (isClose: any, details: any) => {
    setshowAcceptRequestConfirmModal(false);
    setCategoryIdOnChange(details)
    setSelectedRow("");
    let query = {
      offset: tableOptions?.offset ?? 0,
      limit: tableOptions?.limit ?? 10,
      search: debounceSearchValue ?? "",
      categoryId: categoryIdOnChange
    };
    getSquezzMe(query);
  };

  // const handleAcceptPopup = () => {
  //   setshowAcceptRequestConfirmModal(false);
  //   setSelectedRow("");
  // };

  const handleRejectClose = (isReject: any, details: any) => {
    setshowRejectRequestConfirmModal(false);
    setSelectedRow("");
    setCategoryIdOnChange(details)
    let query = {
      offset: tableOptions?.offset ?? 0,
      limit: tableOptions?.limit ?? 10,
      search: debounceSearchValue ?? "",
      categoryId: categoryIdOnChange
    };
    getSquezzMe(query);
  };

  const handleCellClick = (data: any) => {
    // let restaurantData: any = data?.row;
    // let fieldName: string = data?.field;
    // if (fieldName !== "actions") {
    //   navigate("/golf-holes", { state: restaurantData });
    // }
  };

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? "",
      categoryId: categoryIdOnChange || "65c608806782899b0698f069",
      itemId: selectedItemsValue ?? "",
    };
    getSquezzMe(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <MuiTable
            isAddEnable={false}
            columns={columns}
            data={restaurantData?.data?.docs ?? []}
            tableRef={tableRef}
            loading={isGettingrestaurantData}
            isRowClickable={true}
            handleCellClick={handleCellClick}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <Box pb={3}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box display="flex" alignItems="center">
                      <div
                        className="form-group"
                        style={{ minWidth: "145px", marginRight: "20px" }}
                      >
                        <label
                          className="form-label fw-bold"
                          style={{ marginLeft: 5 }}
                        >
                          Category
                        </label>
                        <select
                          onChange={(e) => {
                            handleCategoryChange(e?.target?.value);
                            handleChangeFilters("categoryId", e?.target?.value);
                          }}
                          value={categoryIdOnChange}
                          className="form-select form-select-solid form-select-lg cutom-border"
                        >
                          {categoryData?.data
                            ? categoryData?.data?.docs?.map((category: any) => (
                                <option
                                  value={category?._id}
                                  key={category?._id}
                                >
                                  {category?.name}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                      <div
                        className="form-group"
                        style={{ minWidth: "150px", marginRight: "20px" }}
                      >
                        <label className="form-label fw-bold">Items</label>
                        <select
                          className="form-select form-select-solid form-select-lg cutom-border"
                          value={selectedItemsValue}
                          onChange={(e) => {
                            setSelectedItemsValue(e.target.value);
                            handleChangeFilters("itemId", e?.target?.value);
                          }}
                        >
                          <option value="">Select Item</option>
                          {itemsData && itemsData.length > 0 ? (
                            itemsData.map((item: any) => (
                              <option value={item._id} key={item._id}>
                                {item.name}
                              </option>
                            ))
                          ) : (
                            <option value="" disabled>
                              No data available
                            </option>
                          )}
                        </select>
                      </div>
                      <div style={{ minWidth: "200px" }} className="table-search">
                        <label
                          className="form-label fw-bold"
                          style={{ marginLeft: 5 }}
                        >
                          Search By Name
                        </label>
                        <Box className="with-search-ico">
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid cutom-border"
                          placeholder="Search by name"
                          aria-label="Search"
                          onChange={(e) =>
                            handleChangeFilters("search", e?.target?.value)
                          }
                          value={tableOptions?.search}
                        />
                        </Box>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            }
          />
        </KTCard>
        {showDetailsPopup && (
          <SquezzMeInfoDetails
            details={selectedRow}
            show={showDetailsPopup}
            handleClose={() => setShowDetailsPopup(false)}
          />
        )}
        {showAcceptRequestConfirmModal && (
          <ApproveSquezzMe
            details={selectedRow}
            show={showAcceptRequestConfirmModal}
            handleClose={handleAcceptClose}
          />
        )}
        {showRejectRequestConfirmModal && (
          <RejectSquezzMe
            details={selectedRow}
            show={showRejectRequestConfirmModal}
            handleClose={handleRejectClose}
          />
        )}
      </>
    </Card>
  );
};

export default SquezzMe;
