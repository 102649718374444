import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import PhoneInput from "react-phone-input-2";
import { useMutation, useQuery } from "react-query";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import {
  addEditWellness,
  getCategories,
  getCities,
  getCountries,
  getPaymentProvider,
  getStates,
  uploadPicture,
} from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { charValidate } from "../../components/utility";
import AmountInput from "../../_metronic/layout/components/common/AmountInput";
type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: any;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditWellness = ({ show, handleClose, editData }: Props) => {
  let imgUrl: string = editData?.images
    ? editData?.images[0]
      ? editData?.images[0]
      : ""
    : "";
  const [wellnessImg, setGolfImg] = useState<string>("");
  const [imgFileName, setImgFileName] = useState<string>(
    imgUrl ? imgUrl?.split("/img/")[1] : ""
  );
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState<string>("");
  const [selectedContractorID, setSelectedContractorID] = useState<string>("");
  const [selectedBookingProvider, setSelectedBookingProvider] = useState<string>("");

  const profileDetailsSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    description: Yup.string().required("Description is required"),
    minThreshold: Yup.string().required("ThreshHold amount is required"),
    latitude: Yup.number().required("Latitude is required"),
    longitude: Yup.number().required("Longitude is required"),
    addressName: Yup.string().trim(),
    // .required("Address is required"),
    street: Yup.string().trim(),
    // .required("Street is required"),
    unit: Yup.string().trim(),
    websiteURL: Yup.string().trim().required("Website URL is required"),
    mobileNumber: Yup.string()
      .required("Mobile number is required")
      .min(10, "Mobile number format is incorrect"),
    minSqueezAmount: Yup.string(),

  });

  const {
    data: countryData,
    isLoading: isLoadingCountry,
    error: countryAPIError,
  } = useQuery("countries", getCountries);

  const {
    data: stateData,
    mutateAsync: getStateData,
    isLoading: isLoadingState,
    error: stateAPIError,
  } = useMutation("states", getStates);

  const {
    data: cityData,
    mutateAsync: getCityData,
    isLoading: isLoadingCity,
    error: cityAPIError,
  } = useMutation("cities", getCities);

  const {
    mutateAsync: addWellness,
    isLoading: isLoadingAddWellness,
    error: APIErrorOnAdding,
  } = useMutation("add-golf", addEditWellness);

  const { mutateAsync: pictureUpload } = useMutation(
    "upload-image",
    uploadPicture
  );

  const {
    data: categoryData,
    isLoading: isLoadingCategory,
    error: categoryAPIError,
    refetch: refetchCategories,
  } = useQuery("category", getCategories);

  const { data, isLoading, error ,  mutateAsync: getproviderData} = useMutation("paymentProvider", getPaymentProvider);
  
  const restaurant = categoryData?.data?.docs?.find(
    (s: any) => s?._id == "6633225437f7eb145bc1e92f"
  );

  const formik: any = useFormik({
    initialValues: {
      cateId: restaurant?._id,
      name: editData?.name ? editData?.name?.replace(/^\s+|\s+$/g, "") : "",
      type: editData?.type ?? "",
      description: editData?.description ?? "",
      latitude: editData?.LocationLatLng?.coordinates[0] ?? "",
      longitude: editData?.LocationLatLng?.coordinates[1] ?? "",
      minThreshold: editData?.minThreshold ?? 0,
      addressName: editData?.address?.name
        ? editData?.address?.name?.replace(/^\s+|\s+$/g, "")
        : "",
      street: editData?.address?.street
        ? editData?.address?.street?.replace(/^\s+|\s+$/g, "")
        : "",
      unit: editData?.address?.unit ?? "",
      city: editData?.address?.cityId ?? "",
      zip: editData?.address?.zip ?? "",
      state: editData?.address?.stateId ?? "",
      country: editData?.address?.countryId ?? "",
      websiteURL: editData?.website
        ? editData?.website?.replace(/^\s+|\s+$/g, "")
        : "",
      mobileNumber: editData?.mobileNumber ?? "",
      countryCode: editData?.countryCode ?? "",
      minSqueezAmount: editData?.minSqueezAmount ?? 0,
      IsAutoApprove: editData?.IsAutoApprove ?? true,
      merchantID: editData?.merchantID,
      payment_provider: Object.keys(editData?.paymentInfo || {})[0] || "",
      public_private_key: editData?.paymentInfo?.[Object.keys(editData?.paymentInfo || {})[0]]?.fields?.public_private_key || "",
      contractorID: editData?.paymentInfo?.[Object.keys(editData?.paymentInfo || {})[0]]?.fields?.contractorFields?.[0]?.contractorFieldId || "",
      split_contractor_provider: editData?.paymentInfo?.[Object.keys(editData?.paymentInfo || {})[0]]?.fields?.contractorFields?.[0]?.contractorName || "",
      booking_provider: Object.keys(editData?.bookingInfo || {})[0] || "",
      venueID: editData?.bookingInfo?.[Object.keys(editData?.bookingInfo || {})[0]]?.fields?.venueId || "",
      secret_key: editData?.paymentInfo?.[Object.keys(editData?.paymentInfo || {})[0]]?.fields?.secret_key || "",

    },
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      const paymentProviderData = {
        [values.payment_provider]: {
          fields: {
            public_private_key: values.payment_provider == "fiserv" ? values.public_private_key : "",
            secret_key:  values.payment_provider == "shift4" ? values.secret_key : "",

            accountId: values.merchantID,
            contractorFields: [
              {
                contractorFieldId: values.contractorID,
                contractorName: values.split_contractor_provider,
              },
            ],
          },
        },
      };

      const bookingProviderData = {
        [values.booking_provider]: {
          fields: {
            venueId:values.venueID,
            accountId: values.booking_provider,
          },
        },
      };

      const country = countryData?.data?.find(
        (c: any) => c?.countryId == values?.country
      );
      const state = stateData?.data?.find(
        (s: any) => s?.stateId == values?.state
      );
      const city = cityData?.data?.find((c: any) => c?.cityId == values?.city);
      let bodyParams = {
        _id: editData ? editData?._id : undefined,
        cateId: restaurant?._id,
        name: values?.name ?? "",
        images: wellnessImg ? [wellnessImg] : "",
        website: values?.websiteURL ?? "",
        countryCode: values?.countryCode ?? "1",
        minThreshold: values?.minThreshold ?? 0,
        mobileNumber: values?.mobileNumber
          ? values?.mobileNumber?.slice(`${values?.countryCode?.length}`)
          : "",
        description: values?.description ?? "",
        address: {
          name: values?.addressName
            ? values?.addressName?.replace(/^\s+|\s+$/g, "")
            : "",
          street: values?.street
            ? values?.street?.replace(/^\s+|\s+$/g, "")
            : "",
          unit: values?.unit ? values?.unit?.replace(/^\s+|\s+$/g, "") : "",
          cityId: values?.city ?? "",
          zip: values?.zip ?? "",
          stateId: values?.state ?? "",
          countryId: values?.country ?? "",
          country: country?.name ?? "",
          state: state?.name ?? "",
          city: city?.name ?? "",
        },
        latitude: values?.latitude ?? 72.57,
        longitude: values?.longitude ?? 72.57,
        minSqueezAmount: values?.minSqueezAmount ?? 0,
        IsAutoApprove: values?.IsAutoApprove ?? true,
        merchantID: values?.merchantID,
        paymentInfo: paymentProviderData,
        bookingInfo: bookingProviderData,
        booking_provider: values.booking_provider,
        split_contractor_provider: values.split_contractor_provider,
      };

      let response = await addWellness(bodyParams);
      if (response?.status) {
        snackActions.success(
          response?.message ?? "Wellness Added Successfully!"
        );
        formik.resetForm();
        setImgFileName("");
        setGolfImg("");
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });
  const countryValue = formik.values.country;
  const stateValue = formik.values.state;

  useEffect(() => {
    if (editData) {
      formik.setFieldValue(
        "mobileNumber",
        editData?.countryCode + editData?.mobileNumber
      );
      if (imgUrl) {
        setGolfImg(imgUrl);
      }

      const paymentInfo = editData.paymentInfo || {};
      const bookingInfo = editData.bookingInfo || {};

      const paymentProvider = Object.keys(paymentInfo)[0] || "";
      const bookingProvider = Object.keys(bookingInfo)[0] || "";
      const providerData = paymentInfo[paymentProvider] || {};
      const contractorFields = providerData.fields?.contractorFields || [];
      const venueID = bookingInfo?.[Object.keys(bookingInfo || {})[0]]?.fields?.venueId || "";

      setSelectedPaymentProvider(paymentProvider);
      setSelectedBookingProvider(bookingProvider);
      setSelectedContractorID(contractorFields[0]?.contractorName || "");

      formik.setFieldValue('payment_provider', paymentProvider);
      formik.setFieldValue('merchantID', providerData.fields?.accountId || '');
      formik.setFieldValue('public_private_key', providerData.fields?.public_private_key || '');
      formik.setFieldValue('contractorID', contractorFields[0]?.contractorFieldId || "");
      formik.setFieldValue('split_contractor_provider', contractorFields[0]?.contractorName || "");
      formik.setFieldValue('booking_provider', bookingProvider);
      formik.setFieldValue('venueID', venueID);
    }
  }, [editData]);

  useEffect(() => {
    if (countryValue) {
      getStateData({ countryId: countryValue });
    }
  }, [countryValue]);

  useEffect(() => {
    if (stateValue) {
      getCityData({ stateId: stateValue });
    }
  }, [stateValue]);

  useEffect(() => {
    if (editData || restaurant?._id) {
      getproviderData({ catId:editData ?  editData?.cateId :restaurant?._id});
    }
  }, [editData, restaurant?._id]);



  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setImgFileName(file?.name ?? "");
    formData.append("photo", file);
    if (file) {
      let response = await pictureUpload(formData);
      if (response?.status) {
        setGolfImg(response?.data?.images);
      } else {
        snackActions.error(
          response?.message ?? "Issue in image upload, try again latter!"
        );
      }
    }
  };

  const handlePaymentProviderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedName = e.target.value;
    setSelectedPaymentProvider(selectedName);
    formik.setFieldValue('payment_provider', selectedName);
  };

  const handleContractorProviderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedName = e.target.value;
    setSelectedContractorID(selectedName);
    formik.setFieldValue('split_contractor_provider', selectedName);
  };

  const handleBookingProviderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const provider = e.target.value;
    setSelectedBookingProvider(provider);
    formik.setFieldValue('booking_provider', provider);
  };


  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => {
        setImgFileName("");
        setGolfImg("");
        handleClose(false);
      }}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>{editData?._id ? "Edit" : "Add"} Wellness</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => {
            setImgFileName("");
            setGolfImg("");
            handleClose(false);
          }}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6" style={{ height: "80vh", overflowY: "auto" }}>
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <Container fluid>
            <Row>
              <Col xs={6}>
                <label className="form-label fw-bold">Name</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Name"
                  aria-label="Name"
                  {...formik.getFieldProps("name")}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                <label className="form-label fw-bold">Image upload</label>
                <div>
                  <label
                    htmlFor="golf-img"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer"
                  >
                    {imgFileName
                      ? charValidate(imgFileName, 25)
                      : "Choose File"}
                  </label>
                  <input
                    id="golf-img"
                    type="file"
                    accept="image/*"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Upload Image"
                    aria-label="Upload Image"
                    onChange={handleChangeImageUpload}
                    style={{
                      visibility: "hidden",
                    }}
                  />
                </div>
                {/* {formik.touched.golfImage && formik.errors.golfImage && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.golfImage}
                    </div>
                  </div>
                )} */}
              </Col>
              <Row>
                <Col xs={6}>
                  <label className="form-label fw-bold">Mobile Number</label>
                  <PhoneInput
                    country={"us"}
                    // variant="outlined"
                    onBlur={formik.handleBlur}
                    value={formik.values.mobileNumber}
                    onChange={(value: string, country: any) => {
                      formik.setFieldValue(
                        "countryCode",
                        country?.dialCode || ""
                      );
                      formik.setFieldValue("mobileNumber", value || "");
                    }}
                    inputStyle={{
                      background: "#f9f9f9",
                      border: "none",
                    }}
                    inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    dropdownStyle={{
                      maxHeight: "80px",
                    }}
                  // disableDropdown={true}
                  // disableCountryCode={true}
                  // onlyCountries={["us"]}
                  />
                  {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.mobileNumber}
                      </div>
                    </div>
                  )}
                </Col>

                <Col xs={6}>
                  <label className="form-label fw-bold">Website URL</label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Website URL"
                    aria-label="Website URL"
                    {...formik.getFieldProps("websiteURL")}
                  />
                  {formik.touched.websiteURL && formik.errors.websiteURL && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.websiteURL}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Latitude</label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Latitude"
                  aria-label="Latitude"
                  {...formik.getFieldProps("latitude")}
                />
                {formik.touched.latitude && formik.errors.latitude && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.latitude}
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">Longitude</label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Longitude"
                  aria-label="Longitude"
                  {...formik.getFieldProps("longitude")}
                />
                {formik.touched.longitude && formik.errors.longitude && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.longitude}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <label className="form-label fw-bold">Address Name</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Name"
                  aria-label="Name"
                  {...formik.getFieldProps("addressName")}
                />
                {/* {formik.touched.addressName && formik.errors.addressName && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.addressName}
                    </div>
                  </div>
                )} */}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Street</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Street"
                  aria-label="Street"
                  {...formik.getFieldProps("street")}
                />
                {/* {formik.touched.street && formik.errors.street && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.street}</div>
                  </div>
                )} */}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Unit</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Unit"
                  aria-label="Unit"
                  {...formik.getFieldProps("unit")}
                />
                {/* {formik.touched.unit && formik.errors.unit && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.unit}</div>
                  </div>
                )} */}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Country</label>
                <select
                  disabled={isLoadingCountry}
                  className="form-select form-select-solid form-select-lg"
                  {...formik.getFieldProps("country")}
                  onChange={(e) => {
                    formik.handleChange(e);
                    let selectedCountry = e.target.value;
                    if (!selectedCountry) {
                      formik.setFieldValue("state", "");
                      formik.setFieldValue("city", "");
                      stateData.data = "";
                      cityData.data = "";
                    }
                  }}
                >
                  <option value="">Select Country</option>
                  {countryData?.data
                    ? countryData?.data?.map((country: any) => (
                      <option
                        value={country?.countryId}
                        key={country?._id}
                      >{`${country?.name} (${country?.code})`}</option>
                    ))
                    : null}
                </select>
                {/* {formik.touched.country && formik.errors.country && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.country}</div>
                  </div>
                )} */}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">State</label>
                <select
                  disabled={isLoadingState}
                  className="form-select form-select-solid form-select-lg"
                  {...formik.getFieldProps("state")}
                  onChange={(e) => {
                    formik.handleChange(e);
                    const selectedState = e.target.value;
                    if (!selectedState) {
                      formik.setFieldValue("city", "");
                      cityData.data = "";
                    }
                  }}
                >
                  <option value="">Select State</option>
                  {stateData?.data
                    ? stateData?.data?.map((state: any) => (
                      <option value={state?.stateId} key={state?._id}>
                        {state?.name}
                      </option>
                    ))
                    : null}
                </select>
                {/* {formik.touched.state && formik.errors.state && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.state}</div>
                  </div>
                )} */}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">City</label>
                <select
                  disabled={isLoadingCity}
                  className="form-select form-select-solid form-select-lg"
                  {...formik.getFieldProps("city")}
                >
                  <option value="">Select City</option>
                  {cityData?.data
                    ? cityData?.data?.map((city: any) => (
                      <option value={city?.cityId} key={city?._id}>
                        {city?.name}
                      </option>
                    ))
                    : null}
                </select>
                {/* {formik.touched.city && formik.errors.city && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.city}</div>
                  </div>
                )} */}
              </Col>

              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">Zip</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Zip"
                  aria-label="Zip"
                  {...formik.getFieldProps("zip")}
                />
                {/* {formik.touched.zip && formik.errors.zip && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.zip}</div>
                  </div>
                )} */}
              </Col>
              <Col xs={6} className="pt-3">
                <label className="form-label fw-bold">
                Minimum Threshold Amount
                </label>

                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Minimum Threshold Amount"
                  {...formik.getFieldProps("minThreshold")}
                />
                {formik.touched.minThreshold && formik.errors.minThreshold && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.minThreshold}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
            <Col xs={6} className="pt-6">
                <label className="form-label fw-bold">
                  Maximum Threshold Amount
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Maximum Threshold Amount"
                  {...formik.getFieldProps("minSqueezAmount")}
                />
                {formik.touched.minSqueezAmount &&
                  formik.errors.minSqueezAmount && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.minSqueezAmount}
                      </div>
                    </div>
                  )}

              </Col>
              <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">Auto Approve</label>
                  
                  <Form.Check
                    type="switch"
                    checked={formik.values.IsAutoApprove}
                    id="custom-switch"
                    {...formik.getFieldProps("IsAutoApprove")}
                    className="cursor-pointer"
                    onChange={formik.handleChange}
                  />
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">
                    Merchant ID
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Merchant ID"
                    {...formik.getFieldProps("merchantID")}
                  />
                  {formik.touched.merchantID &&
                    formik.errors.merchantID && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.merchantID}
                        </div>
                      </div>
                    )}
                </Col>

              </Row>
            <Row className="pt-6">
              <Col xs={12} style={{ marginBottom: "48px" }}>
                <label className="form-label fw-bold">Description</label>
                <ReactQuill
                  theme="snow"
                  placeholder="Write something..."
                  modules={AddEditWellness.modules}
                  formats={AddEditWellness.formats}
                  value={formik.values.description}
                  onChange={(content) =>
                    formik.setFieldValue("description", content)
                  }
                  style={{
                    height: "12rem",
                  }}
                />
                {formik.touched.description && formik.errors.description && (
                  <div className="fv-plugins-message-container mt-14">
                    <div className="fv-help-block">
                      {formik.errors.description}
                    </div>
                  </div>
                )}
              </Col>
               {/* Payment Provider */}
             <Row>
                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">
                    Payment Provider
                  </label>
                  <select
                    className="form-select form-select-solid form-select-lg"
                    {...formik.getFieldProps("payment_provider")}
                    onChange={handlePaymentProviderChange}
                  >
                    <option value="">Select Payment Provider</option>
                    {data?.paymentProviders?.map((provider: any) => (
                      <option value={provider.name} key={provider.name}>
                        {provider.name}
                      </option>
                    ))}
                  </select>
                </Col>

                {selectedPaymentProvider === "fiserv" && (
                  <>
                    <Col xs={6} className="pt-6">
                      <label className="form-label fw-bold">
                        Merchant ID
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Merchant ID"
                        {...formik.getFieldProps("merchantID")}
                      />
                    </Col>
                    <Col xs={6} className="pt-6">
                      <label className="form-label fw-bold">
                        Private Key
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Private Key"
                        {...formik.getFieldProps("public_private_key")}
                      />
                    </Col>

                  </>
                )}

                {selectedPaymentProvider === "shift4" && (
                  <>
                    <Col xs={6} className="pt-6">
                      <label className="form-label fw-bold">
                        Secret Key
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Secret Key"
                        {...formik.getFieldProps("secret_key")}
                      />
                    </Col>
                  </>
                )}

                {selectedPaymentProvider.toLocaleLowerCase() === "Stripe".toLocaleLowerCase() && (
                  <>
                    <Col xs={6} className="pt-6">
                      <label className="form-label fw-bold">
                        Merchant ID
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Merchant ID"
                        {...formik.getFieldProps("merchantID")}
                      />
                    </Col>

                    <Col xs={6} className="pt-6">
                      <label className="form-label fw-bold">
                        Partner ID
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder=" Partner ID"
                        {...formik.getFieldProps("partnerID")}
                      />
                    </Col>
                  </>

                )}

                {/* {selectedPaymentProvider === "" && (
                  <Col xs={12} className="pt-1">
                    <p style={{ color: 'red' }}>Please select a payment provider to see the related fields.</p>
                  </Col>
                )} */}

              </Row>
              {/* contractorID */}
              <Row>
                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">
                    Split percentage to selected contractor
                  </label>
                  <select
                    className="form-select form-select-solid form-select-lg"
                    {...formik.getFieldProps("split_contractor_provider")}
                    onChange={handleContractorProviderChange}
                  >
                    <option value="">Select Contractor Provider</option>
                    {data?.contractorProviders?.map((provider: any) => (
                      <option value={provider.name} key={provider.name}>
                        {provider.name}
                      </option>
                    ))}
                  </select>
                </Col>

                {selectedContractorID !== '' && (
                  <>
                    <Col xs={6} className="pt-6">
                      <label className="form-label fw-bold">
                        Contractor ID (Split 5%)
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Contractor ID"
                        {...formik.getFieldProps("contractorID")}
                      />
                    </Col>

                  </>
                )}

              </Row>
              {/* Booking Provider */}
              <Row>
                <Col xs={6} className="pt-6">
                  <label className="form-label fw-bold">
                    Booking Provider
                  </label>
                  <select
                    className="form-select form-select-solid form-select-lg"
                    {...formik.getFieldProps("booking_provider")}
                    onChange={handleBookingProviderChange}
                  >
                    <option value="">Select Booking Provider</option>
                    {data?.bookingProviders?.map((provider: any) => (
                      <option value={provider.name} key={provider.name}>
                        {provider.name}
                      </option>
                    ))}
                  </select>
                </Col>

                {selectedBookingProvider.toLocaleLowerCase() === "mozrest".toLocaleLowerCase() && (
                  <>
                    <Col xs={6} className="pt-6">
                      <label className="form-label fw-bold">
                        Venue ID
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Venue ID"
                        {...formik.getFieldProps("venueID")}
                      />
                    </Col>
                  </>

                )}

              </Row>
              <Col xs={12}>
                <div className="w-100 d-flex align-items-center justify-content-end">
                  <Button
                    onClick={() => {
                      formik.resetForm();
                      setImgFileName("");
                      setGolfImg("");
                      handleClose(false);
                    }}
                    size="lg"
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                  <div className="w-15px"></div>
                  <Button type="submit" size="lg" variant="primary">
                    {isLoadingAddWellness ? "Loading..." : "Submit"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    </Modal>,
    modalsRoot
  );
};

AddEditWellness.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }],
  ],
};
AddEditWellness.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];

export default AddEditWellness;
