import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { KTCard, KTIcon } from "../../_metronic/helpers";
import CustomTooltip from "../../_metronic/layout/components/common/CustomTooltip";
import { PageTitle } from "../../_metronic/layout/core";
import { activeDeActiveGolfClub, deleteGolfClub, deleteRestrictedSlot, deleteWidgetDetails, editAdmin, getGolfClub, getRestaurantInfo, getSlotDetailsById, getWidgetDetails } from "../../api";
import AddEditHoles from "../../components/holes/AddEditHoles";
import MuiTable from "../../components/table/MuiTable";
import AddEditThirdPartyAPI from "../../components/third-party-api/AddEditThirdPartyAPI";
import { charValidate } from "../../components/utility";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import AddEditSlot from "../../components/slot-restriction/AddEditSlot";
import { parseDateWithoutTimezone } from "../../helpers/commonFunctions";
import RestaurantIcon from "@mui/icons-material/GolfCourse";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { de } from "date-fns/locale";
import TokenGeneration from "../../components/slot-restriction/TokenGeneration";

const RestaurantInfo: React.FC = () => {
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [generateTokenModal, setShowGenerateTokenModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
    useState<boolean>(false);
  const [showGolfImgPopup, setShowGolfImgPopup] = useState<boolean>(false);
  const [showAddEditAPIPopup, setShowAddEditAPIPopup] =
    useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [selectedGolfImg, setSelectedGolfImg] = useState<string>("");

  const tableRef = useRef();
  const location: any = useLocation();
  const golfDetailInfo = location?.state;
  const isForTokenGeneration = location?.state?.isForTokenGeneration
  const navigate = useNavigate();

  const {
    mutateAsync: getRestaurantData,
    data: restaurantData,
    isLoading: isGettingRestaurantData,
    error,
  } = useMutation("get-golf", getRestaurantInfo);
  const { data, isLoading, mutateAsync: getSlotdetails } = useMutation("slot-details", getSlotDetailsById);
  const {
    mutateAsync: deleteGolfData,
    isLoading: isdeletingGolf,
    error: deleteGoldAPIError,
  } = useMutation("delete-golf", deleteRestrictedSlot);

  const {
    mutateAsync: deleteWidgetData,
    isLoading: isdeletingWidget,
    error: deleteWidgetAPIError,
  } = useMutation("delete-widget", deleteWidgetDetails);

  useEffect(() => {
    getGolf();
  }, []);
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    restaurantId: restaurantData?.data?._id,
    totalRowCount: undefined
  });

  useEffect(() => {
    if (restaurantData?.data?._id) {
      getSlotdetails({ ...tableOptions, restaurantId: restaurantData?.data?._id })
    }
  }, [restaurantData?.data?._id])

  const htmlString = restaurantData?.data?.description
    ? charValidate(restaurantData?.data?.description, 200)
    : "";
  const descriptionElements = { __html: htmlString };

  const getGolf = async () => {
    await getRestaurantData(golfDetailInfo?.restaurantData?._id ?? "");
  };

  const slotRestrictionData = data?.restrictions

  const columns: GridColDef[] = [
    {
      field: "significantDemandTitle",
      headerName: "Significant Demand Title",
      sortable: false,
      flex:1,
      headerAlign: "center",
      align: "center",
      renderCell: (data: any) => data?.row?.name ?? "N/A",
    },
    {
      field: "startdate",
      headerName: "Start Date",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => parseDateWithoutTimezone(data?.row?.startDate) ?? "N/A",
    },
    {
      field: "enddate",
      headerName: "End Date",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => parseDateWithoutTimezone(data?.row?.endDate) ?? "N/A",
    },
    {
      field: "slotdetails",
      headerName: "Slot Details",
      flex: 2,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => {
        const days = data?.row?.days;

        if (!days || days.length === 0) {
          return <div>N/A</div>;
        }

        // Format the slot details vertically
        const formattedDetails = days.map((day: any) => {
          const dayName = day.day;
          const timeRanges = day.timeRanges.map((timeRange: any) =>
            `${timeRange.startTime} - ${timeRange.endTime}`
          ).join(", ");

          return (
            <div key={dayName}>
              {dayName}: {timeRanges}
            </div>
          );
        });

        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {formattedDetails}
          </div>
        );
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.7,
      sortable: false,
      headerAlign: "right",
      align: "right", 
      cellClassName:"token-actions-align",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Edit">
            <DriveFileRenameOutlineIcon
              onClick={() => {
                setSelectedRow(data?.row);
                setShowAddEditModal(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteSweepIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowDeleteConfirmModal(true);
              }}
              sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
              color="error"
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setSelectedRow("");
    if (isSubmit) {
      getGolf();
    }
  };

  const deleteConfirmModal = (data: any) => {
    return (
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-400px scroll"
        show={showDeleteConfirmModal}
        onHide={handleCloseDelete}
        backdrop="static"
      >
        <div className="modal-header">
          <h2>Delete Restricted Slot</h2>
          {/* begin::Close */}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={handleCloseDelete}
          >
            <KTIcon className="fs-1" iconName="cross" />
          </div>
          {/* end::Close */}
        </div>
        <div className="d-flex flex-column align-items-center">
          <div className="p-5">
            <h4>Are you sure, you want to delete the slot?</h4>
          </div>
          <div className="w-100 d-flex p-5 align-items-center  justify-content-center">
            <Button
              disabled={isdeletingGolf}
              onClick={handleCloseDelete}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button
              disabled={isdeletingGolf}
              onClick={deleteGolf}
              size="lg"
              variant="primary"
            >
              {isdeletingGolf || isdeletingWidget ? "Loading..." : "Submit"}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const golfImagePopup = () => (
    <Modal
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-400px scroll"
      show={showGolfImgPopup}
      onHide={() => setShowGolfImgPopup(false)}
      backdrop={true}
    >
      <Image src={selectedGolfImg} fluid />
    </Modal>
  );

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setShowGenerateTokenModal(false)
  };

  const deleteGolf = async () => {
    let response = await deleteGolfData({
      restaurantId: restaurantData?.data?._id,
      restrictionId: selectedRow?._id
    });
    if (response?.status) {
      snackActions.success(response?.message);
      handleCloseDelete();
      getGolf();
    } else {
      snackActions.error(response?.message);
    }
  };

  const handleBackClick = () => {
    navigate("/restaurant_list");
  };

  const getSlots = async (
    query:
      | {
        offset: number;
        limit: number;
      }
      | undefined
  ) => {
    let response = await getSlotdetails(query)
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          restaurantId: restaurantData?.data?._id,
          totalRowCount: response?.data?.total,
        };
      });
    }
  };

  useEffect(() => {
    if (data) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: data?.offset,
          limit: data?.limit,
          restaurantId: restaurantData?.data?._id,
          totalRowCount: data?.total,
        };
      });
    }
  }, [data])

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        Number(pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0),
      limit: pageSize ?? 10,
      restaurantId: restaurantData?.data?._id
    };
    getSlots(query);
  };

  const GolfDetailsCard = () => {
    return (
      <Card style={{ width: "100%", boxShadow: "none", marginTop: "40px" }} className="mb-4">
        <Card.Body>
          <Card.Title className="pb-5">Restaurant Details</Card.Title>
          <Container style={{marginLeft:0}}>
            <Row>
              <Col xs={6} style={{ paddingLeft: 0 }}>
                <Card.Subtitle className="text-muted">Name:</Card.Subtitle>
                <Card.Text>{restaurantData?.data?.name ?? "N/A"}</Card.Text>
              </Col>
              {/* <Col xs={6}>
                <Card.Subtitle className="text-muted">Time:</Card.Subtitle>
                <Card.Text>
                  {restaurantData?.data?.startTime && restaurantData?.data?.endTime
                    ? `${restaurantData?.data?.startTime} - ${restaurantData?.data?.endTime}`
                    : "N/A"}
                </Card.Text>
              </Col> */}
              <Col xs={6}>
                <Card.Subtitle className="text-muted">Address:</Card.Subtitle>
                <Card.Text>{`${restaurantData?.data?.address?.name}${", "}${restaurantData?.data?.address?.street}${", "}${restaurantData?.data?.address?.city}${", "}${restaurantData?.data?.address?.state}${", "}${restaurantData?.data?.address?.zip}`}</Card.Text>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xs={6} style={{ paddingLeft: 0 }}>
                <Card.Subtitle className="text-muted">
                  Description:
                </Card.Subtitle>
                <Card.Text className="mt-1">
                  <div dangerouslySetInnerHTML={descriptionElements} />
                </Card.Text>
              </Col>
              {/* <Col xs={6}>
                <Card.Subtitle className="text-muted">Type:</Card.Subtitle>
                <Card.Text>{restaurantData?.data?.type ?? "N/A"}</Card.Text>
              </Col> */}
            </Row>
          </Container>
          <Row className="mt-5">
            <Card.Subtitle style={{color:"black !important", fontSize:"14.95px"}}>
              Slot Restriction:
            </Card.Subtitle>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <Card className="p-6">
      <PageTitle>{golfDetailInfo?.restaurantData?.name ?? ""}</PageTitle>
      <>
        <KTCard>
          <MuiTable
            addLabel="Slot Restriction"
            handleAddClick={() => setShowAddEditModal(true)}
            columns={columns}
            data={slotRestrictionData}
            tableRef={tableRef}
            loading={isGettingRestaurantData}
            isBackEnable={true}
            handleBackClick={handleBackClick}
            // isAddEnable={disableAddBtn()}
            tableOptions={tableOptions}
            handlePagination={handlePagination}
            isForRestaurant={true}
            isForTokenGeneration={false}
            actionButtons={
              <div className="pb-5 px-5 d-flex align-items-center">
                {/* <Button
                  className="d-flex align-items-center"
                  onClick={() => setShowAddEditAPIPopup(true)}
                >
                  <KTIcon iconName="plus" className="fs-1" />
                  Add/Edit Third Party API
                </Button>
                <div className="px-3"></div>
                <Button
                  onClick={() =>
                    navigate("/golf-offers", {
                      state: { golfDetailInfo },
                    })
                  }
                  className="d-flex align-items-center"
                >
                  Offers
                </Button> */}
              </div>
            }
          >
            <GolfDetailsCard />
          </MuiTable>
        </KTCard>
        
        {showAddEditModal && (
          <AddEditSlot
            editData={selectedRow}
            show={showAddEditModal}
            handleClose={handleModalClose}
            restaurantData={restaurantData?.data}
          />
        )}
        {showDeleteConfirmModal && deleteConfirmModal(selectedRow)}
        {showGolfImgPopup && golfImagePopup()}
      </>
      
    </Card>
  );
};

export default RestaurantInfo;
